import { FormatKey, IsKeyInObj } from 'src/app/core/utils/global-functions'

// Set classes
export function DisableBtn(params) {
    // console.log("disable button params", params)
    // console.log(classId)
    // switch(classId) {
    //     case 'disable-btn':
    //         console.log(params.value === 0)
    //         return params.value === 0 ? true : false
    // }
}

export const ImportantCellText = (params) => {
    const cellRules = params.colDef.refData?.classList || []
    if (cellRules.includes("important-cell-text")) {
        // console.log("important cell text-params: ", params)
        return true
    }
}

export const NullValue = (params) => {
    if (params.value) {
        // console.log("important cell text-params: ", params)
        return params.value === '--'
    }
}

export const RowValueUpdated = (params) => {

    if (
        !params.node.master && 
        params.data && 
        IsKeyInObj(params.data, 'og_val')
    ) {

        return params.data.setting_value !== params.data.og_val
    };
    return false;

}
export const ParentRowWarning = (params) => {
    if (
        params.context &&
        params.data?.highlight &&
        params.node.master
    ) {
        return true;
    };

    return false;

}

const CellClassRules = {
    // apply red to negative values
    // 'neg-val-waring': (params) => SetClass(params, 'neg-val-waring'),
    // 'not-editable': (params) => SetClass(params, 'not-editable'),
    'null-value': (params) => NullValue(params),
    'important-cell-text': (params) => ImportantCellText(params),
    'disable-btn': (params) => DisableBtn(params)
}
const RowClassRules = {
    'edited-row': (params) => RowValueUpdated(params),
    'parent-row-warning': (params) => ParentRowWarning(params)
}
export { CellClassRules, RowClassRules }

