<mat-card class="card" disabled="!category?.is_enabled">
    <mat-card-header class="card-header">
        <div
            mat-card-avatar
            class="header-icon"
            [attr.enabled]="category.is_enabled"
        >
            <mat-icon class="material-symbols-outlined">{{
                category.icon
            }}</mat-icon>
        </div>
        <mat-card-title
            class="card-title"
            [attr.enabled]="category.is_enabled"
            >{{ category.name }}</mat-card-title
        >
    </mat-card-header>

    <mat-card-content class="card-content">
        <section *ngFor="let section of category.children">
            <a
                class="cat-children"
                aria-label="Navigation link"
                [attr.enabled]="section.is_enabled"
                (click)="section.route && section.is_enabled ? onClickNav(section.route) : ''"
            >
                <h4 [attr.enabled]="section.is_enabled">{{ section.name }}</h4>
                <mat-icon
                    *ngIf="section?.is_enabled"
                    [attr.enabled]="section.is_enabled"
                    >arrow_forward</mat-icon
                >
            </a>
            <p [attr.enabled]="section.is_enabled">{{ section.description }}</p>
        </section>
    </mat-card-content>
</mat-card>
