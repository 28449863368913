// Angular Imports
// =========================================================
import { Component, OnDestroy, OnInit } from '@angular/core';
// rxjs Imports
// =========================================================
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from "lodash";
import { API, Auth } from 'aws-amplify';

// AG Grid / Chart js Imports
// =========================================================
import {
  ColumnApi,
  GridApi,
  GridReadyEvent,
  RowNode,
} from 'ag-grid-community';
// Prime NG Imports
// =========================================================
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
// Mock JSON Data
// =========================================================
import defineSizeOfferingsMainColDefs from '../../../core/json/define_size_offerings/colDefs-main-define_size_offerings.json';
import defineSizeOfferingsDetailColDefs from '../../../core/json/define_size_offerings/colDefs-detail-define_size_offerings.json';
// Custom Imports
// =========================================================
import {
  Size,
  DefineSizeOfferingsData,
} from 'src/app/core/utils/types';
import { FilterService } from 'src/app/core/services/filter.service';
import { SaveSizeOfferingComponent } from '../../03_modals/define-size-offerings/save-size-offerings/save-size-offerings.component';
import { CustomGridOptions } from '../../02_ag-grid-settings/g-ag-grid-options'
import { DefaultDetailGridOptions } from "src/app/components/02_ag-grid-settings/g-ag-grid-variables";
import { DSO_GenerateGridData } from "../../02_ag-grid-settings/02_ag-generate-col-Defs/define-size-offerings/define-size-offerings-main-grid-config";
import { DefineSizeOfferingsDetailGrid } from './dso-detail-grid/define-size-offerings-detail-grid.component';
import { DefineSizeOfferingsService } from 'src/app/core/services/db/define-size-offerings.service';
import { GlobalServices } from 'src/app/core/services/db/global-services.service';
import { IsExternalFilterPresent, DoesExternalFilterPass, CollapsedAllRows } from '../../02_ag-grid-settings/g-ag-grid-functions';
import { SortSizes } from 'src/app/core/utils/global-functions';
import { DataService } from '../../../core/services/data.service';
import { handleError } from 'src/app/core/utils/error-handling';
import { environment } from 'src/environments/environment';
import { ManageSizeOfferingComponent } from '../../03_modals/define-size-offerings/manage-size-offerings/manage-size-offerings.component';
import { DeleteSizeRangesComponent } from '../../03_modals/define-size-offerings/delete-size-ranges/delete-size-ranges.component';

@Component({
  selector: 'app-define-size-offerings',
  templateUrl: './define-size-offerings.component.html',
  styleUrls: ['./define-size-offerings.component.scss'],
})
export class DefineSizeOfferingsComponent implements OnInit, OnDestroy {

  currentDate: Date = new Date();
  disabled: boolean = false;
  position: number;
  ref: DynamicDialogRef;

  private unsubscribe$ = new Subject<void>();
  // Ag Grid Configuration
  private customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this,
      pageTitle: 'Define Size Offering',
      rebalance: (data, parentData?: any) => this.rebalance(data, parentData),
      saveSizeOffering: (data) => this.openSaveSizeOfferingsModal(data, false),
      runQSOR: (data) => this.runQSOR(data),
    },
  };

  gridApi: GridApi;
  columnApi: ColumnApi;
  gridOptions: any = {};
  // Grid Data
  columnDefs: any = []
  detailColumnDefs: any = []

  dimensions: any = [];
  sizeOrder: any = [];
  seasonDates: any = [];

  filtersApplied = {}
  updatingRowData: any[] = [];
  loadingMessage: string = '';
  filtersToDisplay = ['channel', 'product', 'season'];
  currentSeasonSelected: any[] = [];
  initialRender: boolean = true;
  addRowData: any[] = []
  recIdToUpdate: number = null;
  messages: Subscription;
  stateMachine: string = null

  constructor(
    private filterService: FilterService,
    public dialogService: DialogService,
    private dso_services: DefineSizeOfferingsService,
    private globalServices: GlobalServices,
    private dataService: DataService
  ) {
    // Subscribe to side panel filters
    this.filterService.triggerFilterSource$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.filtersApplied = data;
        this.gridApi.onFilterChanged();
      });

    this.initializeData()

    // Set AgGrid Options
    this.gridOptions = {
      ...this.customGridOptions,
      frameworkComponents: {
        ...this.customGridOptions.frameworkComponents,
        defineSizeOfferingsDetailGrid: DefineSizeOfferingsDetailGrid,
      },
      detailCellRenderer: 'defineSizeOfferingsDetailGrid',
      fullWidthCellRenderer: 'rowLoadingCellRenderer',
      rowSelection: 'multiple',
      getRowNodeId: (data) => data.rec_id,
      isExternalFilterPresent: (event) => IsExternalFilterPresent(this.filtersApplied),
      doesExternalFilterPass: (node) => DoesExternalFilterPass(node, this.filtersApplied),
      onSelectionChanged: (event) => this.rowSelectionChanged(event),
      isFullWidthCell: (rowNode) => this.updatingRowData.indexOf(`${rowNode.data.rec_id}`) >= 0,
      getRowHeight: (params) => { if (params.node && params.node.detail) return 330; },
    }

  }
  // Subscribe to Dimensions
  private getAllDimensions(): void {
    this.globalServices.getAllDimensions()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ dimensions }) => { this.dimensions = cloneDeep(dimensions) },
        complete: () => { this.subscribeToRowData() },
        error: (error) => handleError(this.dialogService, error, 'Error fetching dimensions data')
      })
  }
  private subscribeTotSizeOrder(): void {
    this.globalServices.getSizeOrder()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ sizeOrder }) => { this.sizeOrder = sizeOrder },
        complete: () => { },
        error: (error) => handleError(this.dialogService, error, 'Error fetching size order data')
      })
  }
  private subscribeToSeasons(): void {
    this.dso_services.getSeasonStartEndDates()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: seasons => { this.seasonDates = seasons.seasons },
        complete: () => { this.getAllDimensions() },
        error: (error) => handleError(this.dialogService, error, 'Error fetching seasons data')
      })
  }
  private subscribeToRowData(): void {
    if (this.initialRender) {
      this.dso_services.dso_getAllRows()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ({ plans }) => { if (plans) this.transformRowData(plans); },
          complete: () => { },
          error: (error) => handleError(this.dialogService, error, 'Error fetching define size offerings data')
        })
    }
    else {
      this.transformRowData(this.addRowData)
    }
  }
  private getRowByRecId(): void {
    this.dso_services.getRowByRecId(this.recIdToUpdate)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ plan }) => {
          if (plan) {
            console.log("plan: ", plan)
            this.initialRender = false
            this.addRowData = plan
            this.getAllDimensions();
          }
        },
        complete: () => { },
        error: (error) => handleError(this.dialogService, error, 'Error fetching define size offerings row by id')
      })
  }
  async initializeData() {
    try {
      await this.subscribeTotSizeOrder();
      await this.subscribeToSeasons();
    } catch (err) { }
  }

  ngOnInit(): void {
    // getRowByRecId
    this.dataService.connect();
    this.messages = this.dataService.messages$.subscribe((message: any) => {
      console.log('received message from server: ', message);
      if (message?.error) {
        this.resetRowAfterUpdate()
        handleError(this.dialogService, {statusText: "Error processing state machine", status: 404}, `State Machine: ${this.stateMachine}. Error: ${message.error}`)
      } else {
        if (this.stateMachine === "runQSOR") { this.stateMachine = null; return this.getRowByRecId() };
        if (this.stateMachine === "SaveToFutureSeason") { this.stateMachine = null; return this.getAllDimensions() };
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.messages.unsubscribe();
    this.dataService.close();
  }
  // Render AG Grid row Data and Column Definitions
  async renderGrid(mainColDefs, detailColDefs) {
    const params = {
      mainColDefs: mainColDefs,
      detailColDefs: detailColDefs,
    }

    const gridData = await DSO_GenerateGridData(params);

    if (gridData) {
      console.log("Define Size Offereings col defs: ", gridData.mainColDefs)
      this.columnDefs = gridData.mainColDefs
      this.detailColumnDefs = gridData.detailColDefs
    }

  }
  // On Grid Ready
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    // Render Grid Column Definitions
    this.renderGrid(defineSizeOfferingsMainColDefs[0], defineSizeOfferingsDetailColDefs[0])
  }

  // Format Row Data
  transformRowData(objData) {
      console.log("----- Transforming Row Data-------")
      console.log("Initial Render: ", this.initialRender),
      console.log("Dimensions: ", this.dimensions),
      console.log("Size order: ", this.sizeOrder),
      console.log("Seasons: ", this.seasonDates)
      console.log("Data to be formatted: ", objData)

    const rowData = objData.map((plan, i) => {

      let data = plan.data
      let sizes = []
      const dim = this.dimensions.find(dim => dim.id === data.dim_id) || {}

      // Map through size reecords
      data.size_records.forEach(size => {

        sizes.push({
          sizeNm: size.stdSizeCd,
          radc: size.radc || 0,
          sales: size.sales || 0,
          augmented_sales: size.augmented_sales || 0,
          final_c: size.final_c || 0,
          delete: size.existing,
          recoContribPct: size.recoContribPct || 0
        });
      });
      // Final object for the grid row
      const row = {
        season: data.season,
        // days_elapsed: days_elapsed,
        rec_id: data.rec_id,
        dim_id: data.dim_id,
        add_sizes: data.add_sizes,
        sales: data.sales,
        augmented_sales: data.augmented_sales,
        prodId: dim.product_id,
        parent_product_id: dim.product_id, // for filter compatibility
        channel: dim.channel,
        sizeRange: dim.size_range_desc,
        radcs: SortSizes(this.sizeOrder, sizes),
      };

      return row;
    });

    if (this.initialRender) {
      // console.log("formatted Data: ", rowData)
      this.gridApi.setRowData(rowData)
    } else {
      // console.log("new rowData: ", rowData)
      let updatedRowData = []
      this.gridApi.forEachNode((rowNode, index) => {
        let existingRowId = null
        const updated = rowData.find((row, i) => { if (row.rec_id === rowNode.data.rec_id) existingRowId = i; return row.rec_id === rowNode.data.rec_id })
        if (existingRowId !== null) return (
          rowData.splice(existingRowId, 1),
          updatedRowData.push(updated)
        );
        return updatedRowData.push(rowNode.data)

      });
      if (updatedRowData.length > 0) {
        this.gridApi.setRowData([...updatedRowData, ...rowData])
        this.resetRowAfterUpdate()
      }
    }
  }
  // Nested Detail Grid Configuration
  detailCellRendererParams = (parentParams) => {
    const colDefs = this.detailColumnDefs
    return {
      detailGridOptions: {
        ...DefaultDetailGridOptions,
        suppressRowClickSelection: true,
        context: {
          pageTitle: 'Define Size Offering',
        },
        columnDefs: colDefs,
      },
    }
  };

  // Re-balance
  async rebalance(radcs: Size[], parentParams?: any, copyReco = false) {
    let totalUnlocked = 0;
    let available = 100;
    let counter = 0
    let finalTotal = 0;
    let updatedRadcs;
    try {
      console.log("Rebalance radcs: ", radcs)
     await radcs.forEach((pct) => {
        // get the total taken up by the unlocked cells
        if (!pct.locked) totalUnlocked += (pct.final_c * 100)
        // get the maximum allowed total of the unlocked cells by subtracting the locked ones from 100
        if (pct.locked) available -= (pct.final_c * 100);
        counter++
      });

      if (available < 0) {
        // total locked exceeds 100
        // TODO: show error to user
        console.log('cant rebalance, total is ', available);
      }

      if (counter === radcs.length) {
        // get our ratio
        const ratio = available / totalUnlocked;
        // apply ratio to the unlocked cells
       updatedRadcs = await radcs.map(pct => {
          const totalVal = pct.locked ? pct.final_c : parseFloat((pct.final_c * ratio).toFixed(2));
          finalTotal += totalVal
          pct.final_c = pct.locked ? totalVal : totalVal

          if (copyReco) {
            pct.recoContribPct = totalVal;
          }
          return pct
        });
      }
    } catch (error) {

    } finally {
      console.log('Final Total', finalTotal);
      console.log('Updated Radcs', updatedRadcs);

      this.gridApi.applyTransaction({ update: [{...parentParams, radcs: updatedRadcs }] });
    }
  }
  // On row change update the current season that is selected
  rowSelectionChanged(event) {
    const selectedRows = this.gridApi.getSelectedNodes()
    this.currentSeasonSelected = [...new Set(selectedRows.map(row => row.data.season))]
  }
  // Save Size Range Modal
  openSaveSizeOfferingsModal(node: RowNode, isMainGrid: boolean) {
    // console.log("selected nodes: ",  this.gridApi.getSelectedNodes())
    const getRowData = () => isMainGrid ? this.gridApi.getSelectedNodes() : [node]

    if (!isMainGrid) this.gridApi.deselectAll();

    // Trigger Modal
    this.ref = this.dialogService.open(SaveSizeOfferingComponent, {
      showHeader: false,
      dismissableMask: true,
      data: {
        rowData: getRowData(),
        seasonDates: this.seasonDates,
        currentDate: this.currentDate,
        selectedSeason: isMainGrid ? this.currentSeasonSelected[0] : node.data.season,
        selectedProdId: node?.data?.prodId,
        selectedChannel: node?.data?.channel
      },
    });
    // Close Modal
    this.ref.onClose.subscribe((data) => {

      if (data) {
        const { form } = data
        this.loadingMessage = "Saving Size Offerings"

        let mappedData = data.data['rowData'].map(row => {
          this.updatingRowData.push(`${row.data.rec_id}`)

          let newForm = {...form}
          if(!form.selectProdId){
            newForm.selectProdId = row.data.prodId
          }
          if(!form.selectLocation){
            newForm.selectLocation = row.data.channel
          }


          return {
            ...row.data,
            radcs: JSON.stringify(row.data.radcs),
            ...newForm
          }
        })
        this.gridApi.redrawRows()
        console.log("mappedData: ", mappedData)

        for (var i = 0; i < mappedData.length; i++) {
          let mapped = [mappedData[i]]

        // Save Size Offerings Completed
        this.dso_services.dso_saveSizeOfferings(mapped).subscribe({
          next: ({ new_recs }) => {
            console.log("Save Size Offerings Completed: ", new_recs);
            this.addRowData = new_recs
          },
          complete: async () => {
            console.log("save size offerings 1 complete");
            const apiName = 'OrchestratorApiGateway';
            const path = '/task';
            const initBody = {
              body: {
                stateMachineName: `sizeui-orchestrator-service-${environment.stage}-SaveToFutureSeason`,
                identityId: this.dataService.identityId,
                payload: {
                  // new_recs: data.data['rowData'].map(row => row.data.rec_id)
                  new_recs: [mapped[0].rec_id]
                }
              },
              headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
              },
            };

            await API.post(apiName, path, initBody).then((response) => {
              console.log("save size offerings 2: ", response);
              console.log("messages: ", this.messages);
              this.stateMachine = "SaveToFutureSeason"
              this.initialRender = false

            }).catch((error) => {
              console.log("define size offerings error saving: ", error);
            });
          },
          error: (error) => handleError(this.dialogService, error, 'Error Saving Size Offerings')
        })
        }

      } else {
        this.gridApi.deselectAll()
      }
    })
  }
  // Update New Size Offerings Modal
  openUpdateNewSizeOfferingsModal(event) {

    // Trigger Modal
    this.ref = this.dialogService.open(ManageSizeOfferingComponent, {
      showHeader: false,
      dismissableMask: false,
      styleClass: 'user-settings-modal',
      data: this,
    });
    // Close Modal
    this.ref.onClose.subscribe((data) => {

      // if (data) {
      // } else {
      // }
    })
  }

  // Delete size ranges modal
  openDeleteSizeRangesModal(event) {

    // Trigger Modal
    const selectedSizes = this.gridApi.getSelectedNodes()
    const mappedSizes =  selectedSizes.map(object => object.data);
    console.log('selectedSizes111111', selectedSizes)
    this.ref = this.dialogService.open(DeleteSizeRangesComponent, {
      showHeader: false,
      dismissableMask: false,
      styleClass: 'delete-size-ranges-modal',
      data: {selectedSizes: mappedSizes },
    });

    // Close Modal
    this.ref.onClose.subscribe((deletionCompleted) => {

      console.log('deletionCompleted', deletionCompleted);
      if (deletionCompleted) {
        this.gridApi.applyTransaction({ remove: mappedSizes });
      }
    });

  }
  // Run QSOR
  async runQSOR(row) {
    const rec_id = row.data.rec_id
    this.loadingMessage = "Running QSOR"
    this.updatingRowData.push(`${rec_id}`)
    this.recIdToUpdate = rec_id

    if (rec_id) {
      CollapsedAllRows(this.gridApi)
      // TODO: Code commented out because jobs was removed and handled by payloads
      // this.dso_services.runQSOR({ rec_id: rec_id }).subscribe({
      //   next: res => { console.log("qsor next") },
      //   complete: async () => {
      console.log("qsor call 1 complete");
      const apiName = 'OrchestratorApiGateway';
      const path = '/task';
      const initBody = {
        body: {
          stateMachineName: `sizeui-orchestrator-service-${environment.stage}-DefineSizeOfferingsRerun`,
          identityId: this.dataService.identityId,
          payload: { rec_id: rec_id }
        },
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
      };

      await API.post(apiName, path, initBody).then((response) => {
        this.stateMachine = "runQSOR"
        console.log("qsor call 2 complete: ", response);
        console.log("messages: ", this.messages);

        //  const i = this.updatingRowData.indexOf(`${rec_id}`)
        //   this.updatingRowData.splice(i, 1)
        // this.gridApi.redrawRows()
      }).catch((error) => {
        console.log("error", error)
        console.log("Error running QSOR Orchestrator: ", error.response);
      });


      // this.gridApi.deselectAll()
      //   this.resetRowAfterUpdate()
      // },
      // error: (error) => handleError(this.dialogService, error, 'Error processing qsor')
      // }
      // TODO: Test -> with redraws rows out
      this.gridApi.redrawRows()
    }
  }

  resetRowAfterUpdate() {
    this.updatingRowData = []
    this.gridApi.redrawRows()
    this.gridApi.deselectAll()
  }
}

